import { useState, Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { FormLineItem } from 'common/types/Form';
import FormFieldInput from './FormFields/parts/FormFieldInput';
import JobFormField from './JobFormField';
import {
  LineItemContainer,
  LineItemContent,
  ExpandLineItemButton,
  CollapseLineItemButton,
  LineItemRemoveButton,
  LineItemExpandWrapper,
  StyledContextMenu,
  FormInputWrapper,
  Divider,
} from './JobForm.styles';
import PlusIconWithCircle from 'styleguide/src/components/icons/PlusIconWithCircle';
import MinusIconWithCircle from 'styleguide/src/components/icons/MinusIconWithCircle';
import CloseIcon from 'styleguide/src/components/icons/CloseIcon';
import CheckIcon from 'styleguide/src/components/icons/CheckIcon';

type Props = {
  lineItem: FormLineItem;
  index: number;
  isEditing?: boolean;
  setLineItems: Dispatch<SetStateAction<FormLineItem[]>>;
  setTempFilesAdded?: Dispatch<SetStateAction<string[]>>;
  setTempFilesRemoved?: Dispatch<SetStateAction<string[]>>;
  removeLineItem: (index: number) => void;
  formInstanceId?: number;
  isPreviewOnly?: boolean;
};

const LineItem = ({
  lineItem,
  index,
  setLineItems,
  isEditing,
  setTempFilesAdded,
  setTempFilesRemoved,
  removeLineItem,
  formInstanceId,
  isPreviewOnly,
}: Props) => {
  const [open, setOpen] = useState(false);
  const lineRemoveButtonRef = useRef<HTMLSpanElement>();

  const setLineItemDescription = (description: string) => {
    setLineItems((prevLineItems) => {
      const items = [...prevLineItems];
      const item = { ...items[index] };
      item.description = description;
      items[index] = item;
      return items;
    });
  };

  const setLineItemField = useCallback(
    (type: string, title: string, value: string, lineItemIndex: number) => {
      setLineItems((prevLineItems) => {
        const lineItemField = prevLineItems[lineItemIndex];
        const lineItemFieldIndex = lineItemField?.fields?.findIndex(
          (field) => field?.type === type && field?.title === title,
        );

        if (lineItemFieldIndex >= 0) {
          const lineItemsBeforeIndex = prevLineItems.slice(0, lineItemIndex);
          const lineItemsAfterIndex = prevLineItems.slice(lineItemIndex + 1);
          const prevLineItem = prevLineItems[lineItemIndex];
          const prevFields = prevLineItem.fields;
          const fieldsBeforeIndex = prevFields.slice(0, lineItemFieldIndex);
          const fieldsAfterIndex = prevFields.slice(lineItemFieldIndex + 1);

          const newLineItemField = {
            ...prevFields[lineItemFieldIndex],
            value,
          };

          const newLineItem = {
            ...prevLineItem,
            fields: [
              ...fieldsBeforeIndex,
              newLineItemField,
              ...fieldsAfterIndex,
            ],
          };

          return [...lineItemsBeforeIndex, newLineItem, ...lineItemsAfterIndex];
        }
      });
    },
    [setLineItems],
  );

  return (
    <LineItemContainer>
      <>
        {!open && (
          <LineItemExpandWrapper>
            <Divider marginBottom={15} />
            <ExpandLineItemButton
              label={
                lineItem.description ||
                `New Line #${index + 1}${
                  lineItem.description ? `: ${lineItem.description}` : ''
                }`
              }
              icon={<PlusIconWithCircle height={18} width={18} />}
              iconPosition="right"
              onClick={() => {
                setOpen(true);
              }}
            />
            {isEditing && (
              <StyledContextMenu
                contextLabel="Are you sure you want to remove this line item?"
                align="end"
                shouldUsePortal={true}
                items={[
                  {
                    icon: <CloseIcon height={10} width={10} />,
                    onSelect: () => lineRemoveButtonRef.current?.click(),
                  },
                  {
                    icon: <CheckIcon height={10} width={10} />,
                    onSelect: () => removeLineItem(index),
                  },
                ]}
              >
                <LineItemRemoveButton ref={lineRemoveButtonRef}>
                  Remove
                </LineItemRemoveButton>
              </StyledContextMenu>
            )}
          </LineItemExpandWrapper>
        )}
        {open && (
          <>
            <Divider marginBottom={15} />
            <LineItemContent>
              <CollapseLineItemButton
                icon={<MinusIconWithCircle width={18} height={18} />}
                iconPosition="right"
                onClick={() => {
                  setOpen(false);
                }}
              />
              <FormInputWrapper>
                <FormFieldInput
                  disabled={!isEditing}
                  value={lineItem.description}
                  onChange={(e) => {
                    setLineItemDescription(e.target.value);
                  }}
                  placeholder="Line Item Title"
                  label="Work Description"
                />
              </FormInputWrapper>
              {lineItem.fields.map((field, i) => (
                <JobFormField
                  field={field}
                  key={`line_item_field_${i}_${field.type}`}
                  isEditing={isEditing}
                  setFormField={(type, title, value) => {
                    setLineItemField(type, title, value, index);
                  }}
                  setTempFilesRemoved={setTempFilesRemoved}
                  setTempFilesAdded={setTempFilesAdded}
                  fields={lineItem.fields}
                  formInstanceId={formInstanceId}
                  isPreviewOnly={isPreviewOnly}
                />
              ))}
            </LineItemContent>
          </>
        )}
      </>
    </LineItemContainer>
  );
};

export default LineItem;
