import styled from 'styled-components';
import Typography from '../Typography';

export const Container = styled.div`
  width: 24px;
  padding: 0 10px;
`;

export const FileWrapper = styled.div<{ $disable?: boolean }>`
  position: relative;
  cursor: pointer;

  ${({ $disable }) => $disable && `cursor: default;`}
`;

export const FileLabel = styled(Typography)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 8px;
`;
