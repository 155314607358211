import type { SVGAttributes } from 'react';

const MinusIconWithCircle = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="10.5"
      cy="10.5"
      r="10.0962"
      stroke="#132013"
      strokeWidth="0.807692"
    />
    <rect
      x="4.84619"
      y="9.69238"
      width="11.3077"
      height="1.61538"
      fill="currentColor"
    />
  </svg>
);

export default MinusIconWithCircle;
