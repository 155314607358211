import { FormInstance, FormType } from 'common/types/Form';
import { JobIdentifier, JobTitle } from '../../JobDetail.styles';
import Button from 'styleguide/src/components/Button';
import DownloadIcon from 'styleguide/src/components/icons/DownloadIcon';
import { downloadFiles } from 'common/utils/download';
import DownloadReportButton from './JobFormReport/DownloadReportButton';
import {
  JobWorkFormContent,
  JobWorkFormModalContainer,
  Tag,
  NewLineItemButton,
  ModalHeader,
  Divider,
} from './JobForm.styles';
import JobFormField from './JobFormField';
import JobLineItem from './JobLineItem';
import JobFormFooter from './JobFormFooter';
import { useLazyGetWorkOrderQuery } from '../../../../../services/endpoints/workOrder';
import InspectionDetails from './Inspections/Details';
import { FORM_STATUS_MAP } from 'utils/mappings/form';
import PlusIconWithCircle from 'styleguide/src/components/icons/PlusIconWithCircle';
import useJobFormEdit from 'hooks/useJobFormEdit';
import useFileUpload from 'styleguide/src/hooks/useFileUpload';
import { getFieldsFromLineItems } from 'utils/helpers/formHelpers';
import { useEffect, useState } from 'react';
import { WorkOrder } from 'common/types/WorkOrder';

type Prop = {
  formInstance: FormInstance;
  jobId: number;
  jobTitle: string;
  open: boolean;
  onClose: () => void;
  isPreviewOnly?: boolean;
};

const JobWorkFormModal = ({
  formInstance,
  jobId,
  open,
  onClose,
  jobTitle,
  isPreviewOnly,
}: Prop) => {
  const {
    form,
    formLineItems,
    fields,
    attachments,
    isEditing: _isEditing,
    tempFilesAdded,
    setLineItems,
    setTempFilesAdded,
    setTempFilesRemoved,
    setFormField,
    saveFormChanges,
    addLineItem,
    removeLineItem,
    startEdit,
    restorePrev,
  } = useJobFormEdit({ formInstance, isPreviewOnly });
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [getWorkOrder] = useLazyGetWorkOrderQuery();
  const isInspection = form.type === FormType.INSPECTION;
  const { deleteFromStorage } = useFileUpload();
  const isEditing = isPreviewOnly ? true : _isEditing;

  useEffect(() => {
    const setupWorkOrderData = async () => {
      const response = await getWorkOrder(jobId).unwrap();
      setWorkOrder(response);
    };
    if (jobId >= 0 && !isPreviewOnly) {
      setupWorkOrderData();
    }
  }, [jobId, getWorkOrder, isPreviewOnly]);

  const _onClose = () => {
    if (tempFilesAdded?.length) {
      tempFilesAdded.forEach(async (fileUrl) => {
        await deleteFromStorage(fileUrl, `forms/${formInstance.id}`);
      });
    }

    onClose();
  };

  const onDownload = () => {
    downloadFiles(attachments, {
      fileName: 'attachments.zip',
    });
  };

  const requiredFields = fields?.filter((field) => field.required);
  const optionalFields = fields?.filter((field) => !field.required);
  const allFields = [
    ...(fields || []),
    ...(getFieldsFromLineItems(formLineItems) || []),
  ];

  if (!isPreviewOnly && (!workOrder || !fields || fields?.length === 0))
    return null;

  return (
    <JobWorkFormModalContainer open={open} onClose={_onClose}>
      <JobWorkFormContent>
        <JobIdentifier variant="bodySmall">JOB-{jobId}</JobIdentifier>
        {jobTitle && <JobTitle variant="heading3">{jobTitle}</JobTitle>}
        <ModalHeader>
          <Tag>{FORM_STATUS_MAP[formInstance.status]}</Tag>
          {!isPreviewOnly && formInstance.form.type === FormType.FORM && (
            <>
              <DownloadReportButton
                workOrder={workOrder}
                formInstance={formInstance}
              />
              <Button
                icon={<DownloadIcon />}
                iconPosition="right"
                onClick={onDownload}
                disabled={attachments?.length === 0}
              >
                Download Picture in .zip
              </Button>
            </>
          )}
        </ModalHeader>
        {!isInspection && (
          <>
            {requiredFields?.map((field, i) => (
              <JobFormField
                field={field}
                fields={allFields}
                key={`${i}_${field.type}`}
                isEditing={isEditing}
                setFormField={setFormField}
                setTempFilesRemoved={setTempFilesRemoved}
                setTempFilesAdded={setTempFilesAdded}
                formInstanceId={formInstance?.id}
                isPreviewOnly={isPreviewOnly}
              />
            ))}
            {formLineItems?.map((lineItem, index) => (
              <JobLineItem
                key={`${index}_line_item`}
                index={index}
                setLineItems={setLineItems}
                isEditing={isEditing}
                lineItem={lineItem}
                setTempFilesRemoved={setTempFilesRemoved}
                setTempFilesAdded={setTempFilesAdded}
                removeLineItem={removeLineItem}
                formInstanceId={formInstance?.id}
                isPreviewOnly={isPreviewOnly}
              />
            ))}
            {isEditing && formInstance.form?.lineItems && (
              <>
                <Divider marginTop={40} marginBottom={15} />
                <NewLineItemButton
                  onClick={addLineItem}
                  label="Add new line item"
                  icon={<PlusIconWithCircle height={18} width={18} />}
                  iconPosition="right"
                />
              </>
            )}
            {optionalFields?.map((field, i) => (
              <JobFormField
                field={field}
                fields={allFields}
                key={`${i}_${field.type}`}
                isEditing={isEditing}
                setFormField={setFormField}
                setTempFilesRemoved={setTempFilesRemoved}
                setTempFilesAdded={setTempFilesAdded}
                formInstanceId={formInstance?.id}
                isPreviewOnly={isPreviewOnly}
              />
            ))}
          </>
        )}
        {isInspection && (
          <InspectionDetails
            formInstance={formInstance}
            isPreviewOnly={isPreviewOnly}
          />
        )}
      </JobWorkFormContent>
      <JobFormFooter
        title={form.title}
        jobId={jobId}
        isEditing={isEditing}
        saveFields={saveFormChanges}
        startEdit={startEdit}
        restorePrev={restorePrev}
        isPreviewOnly={isPreviewOnly}
      />
    </JobWorkFormModalContainer>
  );
};

export default JobWorkFormModal;
