import Color from 'color';
import styled, { css } from 'styled-components';
import Typography from 'styleguide/src/components/Typography';
import {
  BodyStandard,
  Heading6,
} from 'styleguide/src/components/Typography/Typography.styles';

export const JobDetailContainer = styled.div`
  margin-right: 20px;
  position: relative;
`;

export const JobNotifyWithActionContainer = styled.div`
  background-color: ${(props) =>
    Color(props.theme.colors.terracotta100).alpha(0.5).string()};
  margin-top: 30px;
  margin-bottom: 35px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const JobNotifyContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const JobNotify = styled(Typography)`
  margin-left: 10px;
`;

export const JobActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const JobDetailSection = styled.div`
  ${({ theme }) => css`
    margin-top: 11px;
    padding: 25px 20px 0 40px;
    background: ${theme.colors.white};
    border: 1px solid #eeeeee;
    border-radius: 4px;
  `}
`;

export const JobIdentifier = styled(Typography)`
  font-family: ${(props) => props.theme.fontFamily.main};
  color: ${(props) => props.theme.colors.gray800};
`;

export const JobTitle = styled(Typography)`
  margin-top: 6px;
  margin-right: 25px;
`;

export const JobDescription = styled.div`
  margin-top: 20px;
`;
export const JobItemHead = styled(Typography)`
  color: #717971;
  letter-spacing: 1px;
`;

export const JobItemParentProjectContainer = styled.div`
  margin-bottom: 30px;
  padding: 12px 0;
  display: flex;
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  background: ${(props) => props.theme.colors.wrenoYellowPrimary};
  border-radius: 4px;
`;

export const JobItemParentProject = styled.div``;

export const JobItem = styled(Typography)`
  margin-top: 3px;
  margin-bottom: 30px;
`;

export const JobItemParentProjectWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
`;

export const JobItemParentProjectTitle = styled(Typography)`
  cursor: pointer;
`;

export const JobItemParentProjectId = styled(Typography)`
  color: ${(props) => props.theme.colors.gray800};
  font-size: 11px;
`;

export const JobAvailableTimesGroup = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 30px;
`;

export const JobAvailableTimeTile = styled.div<{ highlight?: boolean }>`
  padding: 12px;
  background: ${({ theme, highlight }) =>
    highlight ? theme.colors.gray200 : theme.colors.white};
  border: 1px solid
    ${({ theme, highlight }) =>
      highlight ? theme.colors.machineGreen100 : theme.colors.gray400};
  border-radius: 6px;
  margin-right: 10px;
  font-weight: 700;
`;

export const JobAvailableDate = styled(Typography)<
  Partial<typeof Typography> & { highlight?: boolean }
>`
  font-family: ${(props) => props.theme.fontFamily.body};
  font-weight: ${({ highlight }) => highlight && '700'};
`;

export const JobAvailableTime = styled(Typography)<
  Partial<typeof Typography> & { highlight?: boolean }
>`
  font-family: ${(props) => props.theme.fontFamily.body};
  color: #717971;
  margin-top: 8px;
  font-weight: ${({ highlight }) => highlight && '700'};
`;

export const JobAssigneeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 30px;
`;

export const JobTaskLists = styled.div`
  margin-top: 12px;
  margin-bottom: 30px;
`;

export const JobTaskListContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
`;

export const JobTaskListRadio = styled.input`
  margin: 0;
  margin-right: 16px;
`;

export const JobAttachmentForms = styled.div`
  display: flex;
  margin-top: 13px;
  margin-bottom: 30px;
`;

export const JobAttachmentForm = styled.div`
  width: 292px;
  height: 58px;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  border-radius: 6px;
  margin-right: 10px;
  padding-left: 11px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const JobAttachmentFormContainer = styled.div`
  margin-left: 8px;
`;

export const JobAttachmentFormTitle = styled(Typography)`
  font-weight: 700;
`;

export const JobAttachmentFormLabel = styled(Typography)`
  color: ${(props) => props.theme.colors.gray800};
  ${Heading6};
`;

export const JobSalaryType = styled.span`
  text-transform: lowercase;
`;

export const AttachmentItem = styled.div`
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  border-radius: 6px;
`;

export const AttachmentFile = styled.div`
  position: relative;
  svg {
    width: 80px;
    height: ${({ theme }) => theme.variables.headerHeight}px;
  }
`;

export const AttachmentFileExtension = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black};
  ${BodyStandard};
  font-weight: 600;
`;

export const CloseJobDetails = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
