import {
  useGenerateReportMutation,
  useLazyViewReportQuery,
} from 'services/endpoints/inspections';
import Button from 'styleguide/src/components/Button';
import { FormInstance } from 'common/types/Form';
import { getErrorMsg } from 'common/utils/error';
import { processInspection } from 'utils/helpers/formHelpers';
import { Divider, JobFormFieldContainer } from '../JobForm.styles';
import { Error } from './Details.style';

interface Props {
  formInstance: FormInstance;
  isPreviewOnly?: boolean;
}

function InspectionDetails({ formInstance, isPreviewOnly }: Props) {
  const { inspectionContext } = processInspection(formInstance);
  const [generateReport, { isLoading: isGenerating, error: generateError }] =
    useGenerateReportMutation();
  const [viewReport, { isFetching: isDownloading, error: downloadError }] =
    useLazyViewReportQuery();

  return (
    <JobFormFieldContainer>
      <Divider />
      {Boolean(generateError || downloadError) && (
        <Error errorMessage={getErrorMsg(generateError || downloadError)} />
      )}
      <Button href={inspectionContext.inspectionUrl} external>
        View in iAuditor
      </Button>
      <Button
        style={{ marginTop: '1rem' }}
        variant="secondary"
        disabled={isGenerating}
        onClick={() => {
          if (isPreviewOnly) return;
          generateReport({ formInstanceId: formInstance.id });
        }}
      >
        {isGenerating ? 'Generating Report...' : 'Regenerate Report'}
      </Button>
      <Button
        style={{ marginTop: '1rem' }}
        variant="secondary"
        onClick={async () => {
          if (isPreviewOnly) return;
          const response = await viewReport({
            formInstanceId: formInstance.id,
          });
          if (response.data && response.data.url) {
            window.open(response.data.url, '_blank');
          } else {
            // eslint-disable-next-line no-console
            console.error('Response successful, but incomplete', response);
          }
        }}
      >
        {isDownloading ? 'Downloading Report...' : 'Download Report'}
      </Button>
    </JobFormFieldContainer>
  );
}

export default InspectionDetails;
