import type { SVGAttributes } from 'react';

const FileEmptyIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={24}
    height={31}
    fill="none"
    viewBox="0 0 24 31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.417 30.134a2.328 2.328 0 0 1-1.708-.708A2.33 2.33 0 0 1 0 27.717V2.551C0 1.885.236 1.315.709.842A2.328 2.328 0 0 1 2.417.134h12.667c.333 0 .646.063.937.188.292.125.563.298.813.52l5.791 5.792c.223.25.396.52.521.812.125.292.188.605.188.938v19.333a2.33 2.33 0 0 1-.709 1.71 2.328 2.328 0 0 1-1.708.707h-18.5ZM16.625 8.051h5.25l-6.5-6.5v5.25c0 .36.118.66.354.896.237.236.535.354.896.354Z"
      fill="currentColor"
      fillOpacity="currentOpacity"
    />
  </svg>
);

export default FileEmptyIcon;
