import Button from 'styleguide/src/components/Button';
import CheckIcon from 'styleguide/src/components/icons/CheckIcon';
import CloseIcon from 'styleguide/src/components/icons/CloseIcon';
import {
  DetailButton,
  FormsIconStyled,
  JobFormFooterBadge,
  JobFormFooterBadgeCaret,
  JobFormFooterBadgeText,
  JobFormFooterButtons,
  JobFormFooterStyled,
  StyledContextMenu,
  DoneButton,
} from './JobForm.styles';

type Prop = {
  title: string;
  jobId: number;
  saveFields: () => void;
  isEditing: boolean;
  startEdit: () => void;
  restorePrev: () => void;
  isPreviewOnly?: boolean;
};

const JobFormFooter = ({
  title,
  jobId,
  isEditing,
  saveFields,
  startEdit,
  restorePrev,
  isPreviewOnly,
}: Prop) => {
  return (
    <JobFormFooterStyled>
      <JobFormFooterBadge>
        <FormsIconStyled />
        <JobFormFooterBadgeText>
          <span>{title} Form</span>
          <JobFormFooterBadgeCaret>{'>'}</JobFormFooterBadgeCaret>
          <DetailButton variant="tertiary" href={`/jobs-and-projects/${jobId}`}>
            JOB-{jobId}
          </DetailButton>
        </JobFormFooterBadgeText>
      </JobFormFooterBadge>
      <JobFormFooterButtons>
        {!isEditing && <Button onClick={startEdit}>Edit Form</Button>}
        {isEditing && !isPreviewOnly && (
          <StyledContextMenu
            contextLabel="Are you sure you want to Save these changes?"
            align="end"
            items={[
              {
                icon: <CloseIcon height={10} width={10} />,
                onSelect: restorePrev,
              },
              {
                icon: <CheckIcon height={10} width={10} />,
                onSelect: saveFields,
              },
            ]}
          >
            <DoneButton>Done</DoneButton>
          </StyledContextMenu>
        )}
      </JobFormFooterButtons>
    </JobFormFooterStyled>
  );
};

export default JobFormFooter;
