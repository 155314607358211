import type { SVGAttributes } from 'react';

const PlusIconWithCircle = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="10.5"
      cy="11"
      r="10.0962"
      stroke="#132013"
      strokeWidth="0.807692"
    />
    <rect
      x="4.84619"
      y="10.1914"
      width="11.3077"
      height="1.61538"
      fill="currentColor"
    />
    <rect
      x="11.3079"
      y="5.3457"
      width="11.3077"
      height="1.61538"
      transform="rotate(90 11.3079 5.3457)"
      fill="currentColor"
    />
  </svg>
);

export default PlusIconWithCircle;
