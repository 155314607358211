import { api } from 'common/store/api';

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['Inspections'] });

const inspectionsEndpoints = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    generateReport: builder.mutation<void, { formInstanceId: number }>({
      query: (data) => ({
        method: 'POST',
        url: 'inspections/generate-report',
        body: data,
      }),
    }),
    viewReport: builder.query<{ url: string }, { formInstanceId: number }>({
      query: (data) => ({
        method: 'POST',
        url: 'inspections/view-report',
        body: data,
      }),
    }),
    getClients: builder.query<{ clientIds: string[] }, void>({
      query: () => ({
        method: 'GET',
        url: 'inspections/clients',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyViewReportQuery,
  useGenerateReportMutation,
  useLazyGetClientsQuery,
} = inspectionsEndpoints;
