import type { SVGAttributes } from 'react';

const DownloadIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 11.25C7.88333 11.25 7.771 11.2293 7.663 11.188C7.55433 11.146 7.45833 11.075 7.375 10.975L4.25 7.87495C4.11667 7.74162 4.05 7.56662 4.05 7.34995C4.05 7.13329 4.11667 6.95828 4.25 6.82495C4.4 6.67495 4.579 6.59562 4.787 6.58695C4.99567 6.57895 5.175 6.64995 5.325 6.79995L7.25 8.72495V1.07495C7.25 0.858285 7.321 0.678952 7.463 0.536952C7.60433 0.395618 7.78333 0.324951 8 0.324951C8.21667 0.324951 8.396 0.395618 8.538 0.536952C8.67933 0.678952 8.75 0.858285 8.75 1.07495V8.72495L10.675 6.79995C10.8083 6.66662 10.9833 6.59995 11.2 6.59995C11.4167 6.59995 11.6 6.67495 11.75 6.82495C11.8833 6.95828 11.95 7.12895 11.95 7.33695C11.95 7.54562 11.8833 7.72495 11.75 7.87495L8.625 10.975C8.54167 11.075 8.446 11.146 8.338 11.188C8.22933 11.2293 8.11667 11.25 8 11.25ZM2.3 15.5C1.8 15.5 1.375 15.325 1.025 14.975C0.675 14.625 0.5 14.2 0.5 13.7V11.75C0.5 11.5333 0.571 11.354 0.713 11.212C0.854333 11.0706 1.03333 11 1.25 11C1.46667 11 1.64567 11.0706 1.787 11.212C1.929 11.354 2 11.5333 2 11.75V13.7C2 13.7666 2.03333 13.8333 2.1 13.9C2.16667 13.9666 2.23333 14 2.3 14H13.7C13.7667 14 13.8333 13.9666 13.9 13.9C13.9667 13.8333 14 13.7666 14 13.7V11.75C14 11.5333 14.0707 11.354 14.212 11.212C14.354 11.0706 14.5333 11 14.75 11C14.9667 11 15.146 11.0706 15.288 11.212C15.4293 11.354 15.5 11.5333 15.5 11.75V13.7C15.5 14.2 15.325 14.625 14.975 14.975C14.625 15.325 14.2 15.5 13.7 15.5H2.3Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadIcon;
