/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export type ErrorType = FetchBaseQueryError | SerializedError | undefined;

type WrenoServerError = {
  message?: string;
  errorCode?: string;
  errors?: string[];
};
/*
reference: https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
*/
export const getErrorMsg = (error?: ErrorType | null): string | undefined => {
  if (isWrenoServerError(error)) {
    if (error.data.errors && error.data.errors.length > 1) {
      return `${error.data.message}: ${error.data.errors.join(', ')}`;
    }
    return error.data.message;
  }
  let message: string | undefined = undefined;
  if (error) {
    if ('status' in error) {
      message =
        'error' in error
          ? error.error
          : (error.data as any)?.message
            ? (error.data as any).message
            : JSON.stringify(error.data);
    } else {
      message = error.message;
    }
  }
  return message;
};

export const getErrorCode = (error?: ErrorType): string | undefined => {
  if (
    error &&
    'data' in error &&
    error.data &&
    typeof error.data === 'object' &&
    'errorCode' in error.data &&
    typeof error.data.errorCode === 'string'
  ) {
    return error.data.errorCode;
  }
  return undefined;
};

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isWrenoServerError(
  error: unknown,
): error is { data: WrenoServerError } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    !!error.data &&
    typeof error.data === 'object' &&
    'message' in error.data &&
    typeof (error as any).data.message === 'string'
  );
}
