import React, { useEffect } from 'react';
import { WorkOrder } from 'common/types/WorkOrder';
import { FormInstance } from 'common/types/Form';
import { FormReportButton } from '../JobForm.styles';
import { usePDF } from '@react-pdf/renderer';
import JobFormReport from './JobFormReport';

type Props = { workOrder: WorkOrder; formInstance: FormInstance };

export default function DownloadReportButton(props: Props) {
  const { workOrder, formInstance } = props;
  const [instance, update] = usePDF({
    document: (
      <JobFormReport
        workOrder={workOrder}
        formInstance={workOrder.formInstances.find(
          (instance) => instance.id === formInstance.id,
        )}
      />
    ),
  });

  useEffect(() => {
    update(
      <JobFormReport
        workOrder={workOrder}
        formInstance={workOrder.formInstances.find(
          (instance) => instance.id === formInstance.id,
        )}
      />,
    );
  }, [workOrder, formInstance]); // eslint-disable-line react-hooks/exhaustive-deps

  function downloadPDF() {
    if (instance.blob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(instance.blob);
      link.download = `form${formInstance.id}report.pdf`;
      link.click();
      link.remove();
    }
  }

  return (
    <FormReportButton onClick={downloadPDF} isLoading={instance.loading}>
      Export as PDF
    </FormReportButton>
  );
}
