import { HTMLAttributes } from 'react';
import theme from '../../themes/theme';
import FileEmptyIcon from '../icons/FileEmptyIcon';
import { Container, FileLabel, FileWrapper } from './FormAbbreviation.styles';

export interface Prop extends HTMLAttributes<HTMLDivElement> {
  value: string;
  fill?: boolean;
  disable?: boolean;
}

const FormAbbreviation = ({ value, fill, disable, ...props }: Prop) => {
  if (!value) return null;
  return (
    <Container {...props}>
      <FileWrapper>
        <FileWrapper $disable={disable}>
          {fill ? (
            <FileEmptyIcon
              color={theme.colors.machineGreen600}
              fillOpacity={1}
            />
          ) : (
            <FileEmptyIcon fillOpacity={0.1} />
          )}
          <FileLabel variant="ctaSmall" color={fill ? 'white' : 'gray800'}>
            {value}
          </FileLabel>
        </FileWrapper>
      </FileWrapper>
    </Container>
  );
};

export default FormAbbreviation;
