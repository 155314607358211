import {
  FormInstanceField,
  FormInstanceLineItem,
  FormInstance,
} from 'common/types/Form';
import { processForm } from 'utils/helpers/formHelpers';
import { useUpdateJobFormInstanceMutation } from '../services/endpoints/workOrder';
import { useState, useCallback, useRef } from 'react';
import useFileUpload from 'styleguide/src/hooks/useFileUpload';

const useJobFormEdit = ({
  formInstance,
  isPreviewOnly,
}: {
  formInstance: FormInstance;
  isPreviewOnly?: boolean;
}) => {
  const [formFields, setFormFields] = useState<FormInstanceField[]>(
    formInstance.fields,
  );
  const formFieldsPrev = useRef<FormInstanceField[]>();
  const [lineItems, setLineItems] = useState<FormInstanceLineItem[]>(
    formInstance.lineItems || undefined,
  );
  const lineItemsPrev = useRef<FormInstanceLineItem[]>();
  const [tempFilesAdded, setTempFilesAdded] = useState<string[]>([]);
  const [tempFilesRemoved, setTempFilesRemoved] = useState<string[]>([]);
  const [materialListTotal, setMaterialListTotal] =
    useState<{ [key in string]: number }>();
  const {
    fields,
    form,
    attachments,
    lineItems: formLineItems,
  } = processForm({
    ...formInstance,
    fields: formFields,
    lineItems,
  });
  const [isEditing, setIsEditing] = useState(false);

  // eslint-disable-next-line no-console
  console.info('Form Debug Data', {
    formId: form.id,
    title: form.title,
    parsedFields: fields,
    rawFields: form.fields,
    attachments,
    lineItems: formLineItems,
  });

  const [updateForm] = useUpdateJobFormInstanceMutation();
  const { deleteFromStorage } = useFileUpload();

  const setFormField = useCallback(
    (type: string, title: string, value: string) => {
      setFormFields((prevState) => {
        const fieldIndex = prevState?.findIndex(
          (field) => field.type === type && field.title === title,
        );
        if (fieldIndex >= 0) {
          const fieldsBeforeIndex = prevState?.slice(0, fieldIndex);
          const fieldsAfterIndex = prevState?.slice(fieldIndex + 1);
          return [
            ...fieldsBeforeIndex,
            { ...prevState[fieldIndex], value },
            ...fieldsAfterIndex,
          ];
        }
        return prevState;
      });
    },
    [setFormFields],
  );

  const saveFormChanges = useCallback(
    async (_lineItems?: FormInstanceLineItem[]) => {
      if (isPreviewOnly) return;
      setIsEditing(false);
      formFieldsPrev.current = formFields;
      lineItemsPrev.current = lineItems;
      setTempFilesAdded([]);
      if (tempFilesRemoved?.length) {
        tempFilesRemoved.forEach(async (fileUrl) => {
          await deleteFromStorage(fileUrl, `forms/${formInstance.id}`);
        });
      }
      await updateForm({
        workOrderId: formInstance.workOrderId,
        formInstanceId: formInstance.id,
        formInstance: {
          ...formInstance,
          fields: formFields,
          lineItems: _lineItems || lineItems,
        },
      }).unwrap();
    },
    [formFields, updateForm, formInstance, lineItems, tempFilesRemoved], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const addLineItem = () => {
    setLineItems((prevLineItems) => {
      const items = [
        ...(prevLineItems || []),
        {
          description: '',
          fields: formInstance.form.lineItems.fields as FormInstanceField[],
        },
      ];
      return items;
    });
  };

  const removeLineItem = useCallback((index: number) => {
    setLineItems((prevLineItems) => {
      const items = [...prevLineItems];
      items.splice(index, 1);
      return items;
    });
  }, []);

  const startEdit = useCallback(() => {
    formFieldsPrev.current = formFields;
    lineItemsPrev.current = lineItems;
    setIsEditing(true);
  }, [setIsEditing, formFields, lineItems]);

  const restorePrev = useCallback(() => {
    setFormFields(formFieldsPrev.current);
    setLineItems(lineItemsPrev.current);
    setIsEditing(false);
  }, [setIsEditing, formFieldsPrev, lineItemsPrev]);

  return {
    form,
    formLineItems,
    fields,
    attachments,
    isEditing,
    tempFilesRemoved,
    tempFilesAdded,
    materialListTotal,
    setMaterialListTotal,
    setLineItems,
    setTempFilesAdded,
    setTempFilesRemoved,
    setIsEditing,
    setFormField,
    saveFormChanges,
    addLineItem,
    removeLineItem,
    startEdit,
    restorePrev,
  };
};

export default useJobFormEdit;
