import type { FormStatus, FormType, FormLocation } from 'common/types/Form';

export const FORM_STATUS_MAP: { [key in FormStatus]: string } = {
  DRAFT: 'Draft',
  IN_PROGRESS: 'In Progress',
  SUBMITTED: 'Submitted',
};

export const FORM_TYPE_MAP: { [key in FormType]: string } = {
  FORM: 'Form',
  INSPECTION: 'Inspection',
};

export const FORM_LOCATION_MAP: { [key in FormLocation]: string } = {
  PHYSICAL: 'Physical',
  VIRTUAL: 'Virtual',
};
