import { FormField } from 'common/types/Form';
import { FORM_COMPONENTS_MAP } from 'utils/helpers/formHelpers';
import { Divider, JobFormFieldContainer } from './JobForm.styles';
import { Dispatch, SetStateAction } from 'react';

export type FormFieldProps = {
  field: FormField;
  isEditing?: boolean;
  fields: FormField[];
  setFormField: (type: string, title: string, value: string) => void;
  setTempFilesAdded?: Dispatch<SetStateAction<string[]>>;
  setTempFilesRemoved?: Dispatch<SetStateAction<string[]>>;
  formInstanceId?: number;
  isPreviewOnly?: boolean;
};

const JobFormField = ({ field, ...rest }: FormFieldProps) => {
  const { type } = field;
  const Component = FORM_COMPONENTS_MAP[type];

  if (!Component) {
    // eslint-disable-next-line no-console
    console.warn('Unexpected Form Component Type Detected', type);
    return null;
  }
  return (
    <JobFormFieldContainer>
      <Divider />
      <Component field={field} {...rest} />
    </JobFormFieldContainer>
  );
};

export default JobFormField;
