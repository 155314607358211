import { InputWithMeta } from 'client-zip';
import canUseDOM from './canUseDOM';
import type {
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import type { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { format } from 'date-fns';

export type DownloadFileMapper = (
  url: string,
  index: number,
) => Promise<InputWithMeta>;

export async function downloadFiles(
  filePaths: string[],
  options: { fileName: string; mapper?: DownloadFileMapper },
) {
  if (!filePaths || !filePaths.length) {
    // eslint-disable-next-line no-console
    console.warn('Attempting to generating empty zip file. Skipping.');
    return;
  }
  // We dynamically load client-zip so we can still SSR the app
  const { downloadZip } = await import('client-zip');
  // load all files which will be included in zip
  let downloads: InputWithMeta[] = [];
  if (options && options.mapper) {
    downloads = await Promise.all(
      filePaths.map((url, index) => options.mapper!(url, index)),
    );
  } else {
    downloads = await Promise.all(filePaths.map((url) => fetch(url)));
  }
  // get the ZIP stream in a Blob
  const blob = await downloadZip(downloads).blob();
  // call downloadFile to download the Blob
  await downloadFile(blob, options.fileName);
}

export async function downloadFile(file: Blob | string, fileName: string) {
  if (!canUseDOM || !file) return;

  // if file is a string, fetch it
  if (typeof file === 'string') {
    file = await fetchFileFromUrl(file);
  }

  // make and click a temporary link to download the Blob
  const link = document.createElement('a');
  const href = URL.createObjectURL(file);
  link.href = href;
  link.download = fileName;
  link.click();
  link.remove();
  URL.revokeObjectURL(href);
}

export const generateFileDownloadName = (
  fileName: string,
  companyName?: string,
) => {
  return `${companyName ? `${companyName} - ` : ''}${fileName} - ${format(new Date(), 'yyyy-MM-dd')}`;
};

// Currently hard-coded to csv, we should add support for other file types if needed.
export const downloadQueryHandler = async (
  url: string,
  baseQuery: (
    arg: string | FetchArgs,
  ) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, unknown>>,
  fileName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any> | undefined,
) => {
  try {
    const { showSaveFilePicker } = await import('native-file-system-adapter');
    const fileHandle = await showSaveFilePicker({
      suggestedName: fileName ? `${fileName}.csv` : 'download.csv',
      types: [
        {
          description: 'Comma-separated values files',
          accept: {
            'text/csv': ['.csv'],
          },
        },
      ],
    });
    if (fileHandle) {
      const result = await baseQuery({
        url,
        params,
        responseHandler: async (response) => {
          return response.status === 200
            ? (response.body as ReadableStream<Uint8Array>)
            : await response.json();
        },
        cache: 'no-cache',
      });

      if (result.error) {
        return { error: result.error };
      }

      const writable = await fileHandle.createWritable();
      await (result?.data as ReadableStream<Uint8Array>)?.pipeTo(writable);
      return { data: true };
    }
    return { data: false };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error downloading file', error);
    const errorDetails: FetchBaseQueryError = {
      error:
        'Something went wrong with the export. To successfully save the report, please make sure to select a save location.',
      status: 'CUSTOM_ERROR',
    };
    return {
      error: errorDetails,
    };
  }
};

async function fetchFileFromUrl(url: string) {
  const response = await fetch(url);
  return (await response.blob()) as File;
}
